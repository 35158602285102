import * as React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import useTheme from '@material-ui/core/styles/useTheme';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TelegramIcon from '@material-ui/icons/Telegram';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Store, User } from '../types';
import { useClient } from '../ClientProvider';
import debounce from '../debounce';
import useFlash from '../hooks/useFlash';

interface Row {
    guid: string;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    lastConnection?: Date;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    list: {
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 650,
    },
    actions: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    search: {
        padding: theme.spacing(2, 0, 2, 0),
    },
    searchInput: {
        minWidth: '300px',
    },
}));

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles({});
    const theme = useTheme();
    const {
        count, page, rowsPerPage, onChangePage,
    } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.actions}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const ManagedUsersPage = (): JSX.Element => {
    const classes = useStyles({});
    const client = useClient();
    const me = useSelector((state: Store): User => state.me);
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        client.getManagedUsers(me).then((response) => {
            const us: Row[] = [];
            response.data.forEach((userData: {}): void => {
                let date = null;
                const lastLoginAt = userData.last_login_at;
                if (lastLoginAt) {
                    date = moment.tz(lastLoginAt.date, lastLoginAt.timezone).toDate();
                }
                us.push({
                    guid: userData.guid,
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    email: userData.email,
                    username: userData.username,
                    lastConnection: date,
                });
            });
            setUsers(us);
        });
    }, []);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [search, setSearch] = React.useState('');

    const handleSearch = debounce((value) => setSearch(value), 200, false);

    const filteredUsers = users.filter((user: Row): boolean => {
        const s = search.toLowerCase();
        return user.firstname.toLowerCase().indexOf(s) !== -1
            || user.lastname.toLowerCase().indexOf(s) !== -1
            || user.username.toLowerCase().indexOf(s) !== -1
            || user.email.toLowerCase().indexOf(s) !== -1;
    });

    const [performingAction, setPerformingAction] = React.useState(false);
    const { success, error } = useFlash();

    const handleSendCredentials = (user: User) => (e): void => {
        e.preventDefault();

        setPerformingAction(true);
        client.sendCredentials(user).then((): void => {
            success('Identifiants envoyés avec succès.');
        }).catch((): void => {
            error('Une erreur est survenue.');
        }).finally((): void => {
            setPerformingAction(false);
        });
    };

    const handleLogAs = (user: User) => (e): void => {
        e.preventDefault();

        setPerformingAction(true);
        client.logAs(user).then((): void => {
            window.location.reload();
        }).catch((): void => {
            error('Une erreur est survenue.');
        }).finally((): void => {
            setPerformingAction(false);
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.search}>
                <TextField
                    id="input-with-icon-textfield"
                    placeholder="Rechercher"
                    className={classes.searchInput}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Identifiant</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Dernière connexion</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredUsers
                    ).map((user: Row) => (
                        <TableRow key={user.guid}>
                            <TableCell component="th" scope="row">
                                {user.username}
                            </TableCell>
                            <TableCell>{`${user.firstname} ${user.lastname}`}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                {user.lastConnection && moment(user.lastConnection).calendar()}
                            </TableCell>
                            <TableCell>
                                <Tooltip title={`Se connecter en tant que ${user.username}`}>
                                    <IconButton
                                        edge="end"
                                        onClick={handleLogAs(user)}
                                        disabled={performingAction}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Envoyer les identifiants">
                                    <IconButton
                                        edge="end"
                                        onClick={handleSendCredentials(user)}
                                        disabled={performingAction}
                                    >
                                        <TelegramIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={5}
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
};

export default ManagedUsersPage;
